<template>
    <b-modal
        id="confirm-modal"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
    >
        <div class="d-flex flex-column align-items-center justify-center-center my-2">
            <feather-icon
                id="view-modal"
                icon="AlertCircleIcon"
                :class="'text-'+type+ ' mb-2'"
                size="80"
            />
            <div class="text-center">
                <h2>Tem certeza que deseja {{ action }}?</h2>
                <span>{{ infoTextModal }}</span>
            </div>
            <div class="d-flex mt-2">
                <b-button :disabled="loading" variant="primary-button" class="mr-2" @click="cancelAction">
                    {{ textButtonCancel }}
                </b-button>
                <b-button :disabled="loading" :variant="'outline-'+type" @click="confirmAction" >
                    <span v-if="loading" class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                    Sim, {{ action }}
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
    components: {
        BModal, BButton
    },

    props: {
        modalShow: {
            required: true,
            type: Boolean
        },
        typeModal: {
            required: true,
            type: String
        },
        infoText: {
            type: String
        }
    },

    data() {
        return {
            loading: false,
            textComplement: '',
            modalTypes: [
                {
                    'type': 'success',
                    'action': 'ativar',
                    'textButtonCancel': 'Cancelar',
                    'infoText': `Esta ação permitirá o uso desta vacina em novas campanhas de vacinação.`
                },
                {
                    'type': 'warning',
                    'action': 'inativar',
                    'textButtonCancel': 'Cancelar',
                    'infoText': `Esta vacina não poderá ser utilizada em novas campanhas de vacinação.`
                },
                {
                    'type': 'danger',
                    'action': 'cancelar',
                    'textButtonCancel': 'Continuar Editando',
                    'infoText': 'Todos os dados não salvos serão perdidos.'
                }
            ],
            type: '',
            action: '',
            textButtonCancel: '',
            infoTextModal: ''
        }
    },

    methods: {
        confirmAction(modalEvent) {
            modalEvent.preventDefault();

            this.loading = true;

            this.$emit('confirmed', modalEvent);
        },

        cancelAction(modalEvent) {
            modalEvent.preventDefault();

            this.$emit('canceled', modalEvent);
        }
    },

    watch: {
        modalShow() {
            this.loading = false;

            this.modalTypes.map(item => {
                if(item.type == this.$props.typeModal) {
                    this.action = item.action;
                    this.textButtonCancel = item.textButtonCancel;
                    this.infoTextModal = this.$props.infoText ? this.$props.infoText : item.infoText;
                    this.type = item.type;
                }
            });
        }
    }
}
</script>

